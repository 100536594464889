import {
  GET_EFACTURAS_COMPRAS,
  GET_EFACTURAS_COMPRAS_SUCCESS,
  GET_EFACTURAS_COMPRAS_FAILURE,
  SUBIR_EFACTURA_COMPRAS,
  SUBIR_EFACTURA_COMPRAS_SUCCESS,
  SUBIR_EFACTURA_COMPRAS_FAILURE,
  GET_MAS_EFACTURAS_COMPRAS,
  GET_MAS_EFACTURAS_COMPRAS_SUCCESS,
  GET_MAS_EFACTURAS_COMPRAS_FAILURE,
} from "../actions/types.js";

const initialState = {
  efacturas_compras: [],
  next: "",
  previous: "",
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_EFACTURAS_COMPRAS:
      return {
        ...state,
        efacturas_compras: [],
        isLoading: true,
      };
    case GET_EFACTURAS_COMPRAS_SUCCESS:
      return {
        ...state,
        efacturas_compras: action.payload.results,
        next: action.payload.next,
        previous: action.payload.previous,
        isLoading: false,
      };
    case GET_EFACTURAS_COMPRAS_FAILURE:
    case SUBIR_EFACTURA_COMPRAS_FAILURE:
    case GET_MAS_EFACTURAS_COMPRAS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SUBIR_EFACTURA_COMPRAS:
    case GET_MAS_EFACTURAS_COMPRAS:
      return {
        ...state,
        isLoading: true,
      };
    case SUBIR_EFACTURA_COMPRAS_SUCCESS:
      return {
        ...state,
        efacturas_compras: [...state.efacturas_compras, ...action.payload],
        isLoading: false,
      };
    case GET_MAS_EFACTURAS_COMPRAS_SUCCESS:
      return {
        ...state,
        efacturas_compras: [...state.efacturas_compras, ...action.payload.results],
        next: action.payload.next,
        previous: action.payload.previous,
        isLoading: false,
      };

    default:
      return state;
  }
}
