import axios from "../axiosConfig";

import { FETCH_ESTADOS, GET_ESTADOS } from "./types";
import { tokenConfig } from "./auth";

export const getEstados = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_ESTADOS,
  });
  axios
    .get(`/estados/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ESTADOS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
