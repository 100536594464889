import axios from "../axiosConfig";

import { FETCH_PRESENTACIONES, GET_PRESENTACIONES, SET_PRESENTACION, LIMPIAR_PRESENTACION } from "./types";
import { tokenConfig } from "./auth";

// GET PRESENTACIONES DEL ARTICULO
export const getPresentaciones = (articulo) => (dispatch, getState) => {
  dispatch({
    type: FETCH_PRESENTACIONES,
    articulo: articulo,
  });
  axios
    .get(`/presenarticulo/?articulo=${articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PRESENTACIONES,
        payload: res.data,
        articulo: articulo,
      });
    })
    .catch((err) => console.log(err));
};

export const limpiarPresentacion = () => (dispatch) => {
  dispatch({
    type: LIMPIAR_PRESENTACION,
  });
};

export const setPresentacion = (presentacion) => (dispatch) => {
  dispatch({
    type: SET_PRESENTACION,
    payload: presentacion,
  });
};
