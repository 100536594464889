import axios from "../axiosConfig";

import { FETCH_OFERTAS, GET_OFERTAS } from "./types";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getOfertas =
  (domicilio_envio = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_OFERTAS,
      payload: true,
    });
    axios
      .get(`/articulos/ofertas/?domicilio_envio=${domicilio_envio}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_OFERTAS,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
