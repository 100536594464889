import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOfertasMultiples } from "../../../actions/ofertasMultiples";
import Descripcion from "../Descripcion";
import i18n from "../../../lang/idiomas";

export class OfertasMultiples extends Component {
  static propTypes = {
    articulo: PropTypes.string.isRequired,
  };

  componentDidMount() {
    if (this.props.articulo) {
      this.props.getOfertasMultiples(this.props.articulo);
    }
  }

  componentDidUpdate(prevProps) {
    window.wookie();
  }

  render() {
    return (
      <Fragment>
        {this.props.ofertas_multiples && this.props.ofertas_multiples.ofertas ? (
          this.props.ofertas_multiples.ofertas.length > 0 ? (
            <div className="tt-collapse-block">
              <div className="tt-item active">
                <div className="tt-collapse-title">{i18n.t("eparciales.epofer")}</div>

                <div className="tt-collapse-content" style={{ display: "block" }}>
                  <div className="tt-table-responsive">
                    <table className="tt-table-shop-01">
                      <tbody>
                        <tr>
                          <th style={{ textAlign: "center" }} scope="col">
                            {i18n.t("eparciales.epcantne")}
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            {i18n.t("eparciales.epartreg")}
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            {i18n.t("eparciales.epcantre")}
                          </th>
                        </tr>
                        {this.props.ofertas_multiples.ofertas
                          ? this.props.ofertas_multiples.ofertas.map((oferta, index) => {
                              return (
                                <tr key={oferta.articulo_oferta + "-" + oferta.cantidad_necesario + "-" + index}>
                                  <td style={{ textAlign: "center" }}>{oferta.cantidad_necesario + " " + oferta.presentacion_necesaria}</td>
                                  <td style={{ textAlign: "center" }}>
                                    <Descripcion codigo_articulo={oferta.articulo_oferta} />{" "}
                                  </td>
                                  <td style={{ textAlign: "center" }}>{oferta.cantidad_regalada + " " + oferta.presentacion_regalo} </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ofertas_multiples: state.ofertasMultiples.ofertas_multiples,
});

export default connect(mapStateToProps, {
  getOfertasMultiples,
})(OfertasMultiples);
