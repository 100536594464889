import axios from "../axiosConfig";

import { GET_GALERIA, GET_GALERIA_FAIL, GET_GALERIA_SUCCESS } from "./types";

import { tokenConfig } from "./auth";

// GET GALERIA IMAGENES ARTICULO
export const getGaleria = (codigo_articulo) => (dispatch, getState) => {
  dispatch({
    type: GET_GALERIA,
    codigo_articulo: codigo_articulo,
  });
  axios
    .get(`/galeria/?search=${codigo_articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_GALERIA_SUCCESS,
        payload: res.data,
        codigo_articulo: codigo_articulo,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_GALERIA_FAIL,
        codigo_articulo: codigo_articulo,
      });
    });
};
