import { GET_TARIFAS_ENVIO_PORTES, FETCH_TARIFAS_ENVIO_PORTES } from "../actions/types.js";

const initialState = {
  tarifas: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TARIFAS_ENVIO_PORTES:
      return {
        ...state,
        tarifas: action.payload,
        isLoading: false,
      };
    case FETCH_TARIFAS_ENVIO_PORTES:
      return {
        ...state,
        tarifas: [],
        isLoading: true,
      };
    default:
      return state;
  }
}
