import { FETCH_PESTANAS, GET_PESTANAS, FETCH_WIDGETS, GET_WIDGETS } from "../actions/types.js";

const initialState = {
  pestanas: [],
  widgets: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PESTANAS:
      return {
        ...state,
        pestanas: [],
        widgets: [],
        isLoading: true,
      };
    case GET_PESTANAS:
      return {
        ...state,
        pestanas: action.payload,
        isLoading: false,
      };
    case FETCH_WIDGETS:
      return {
        ...state,
        widgets: [],
        isLoading: true,
      };
    case GET_WIDGETS:
      return {
        ...state,
        widgets: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
