import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { createMessage } from "../../actions/messages";
import DomiciliosEnvio from "./DomiciliosEnvio";
import FormasEnvio from "./FormasEnvio";
import Almacenes from "./Almacenes";
import Rutas from "./Rutas";
import { actualizaPedido } from "../../actions/pedidos";
import i18n from "../../lang/idiomas";
import "../../css/resumen.css";

export class Pedido extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    domicilio_envio: PropTypes.object.isRequired,
    forma_envio: PropTypes.object.isRequired,
    almacen_recogida: PropTypes.object.isRequired,
    ruta: PropTypes.object.isRequired,
    hash: PropTypes.string,
  };

  componentDidUpdate() {
    window.wookie();
  }

  actualizarPedido = (e) => {
    e.preventDefault();

    if (this.props.forma_envio.tipo === undefined || this.props.forma_envio.tipo === null) {
      this.props.createMessage("Debe seleccionar una forma de envío.", "error");
    } else if (this.props.forma_envio.tipo === "D" && (this.props.domicilio_envio.numero_direccion === undefined || this.props.domicilio_envio.numero_direccion === null)) {
      this.props.createMessage("Debe seleccionar un domicilio de envío.", "error");
    } else if (this.props.forma_envio.tipo === "T" && (this.props.almacen_recogida.almacen === undefined || this.props.almacen_recogida.almacen === null)) {
      this.props.createMessage("Debe seleccionar un lugar de recogida.", "error");
    } else if (this.props.forma_envio.tipo === "R" && (this.props.ruta.almacen === undefined || this.props.ruta.almacen === null)) {
      this.props.createMessage("Debe seleccionar una ruta de reparto.", "error");
    } else {
      this.props.actualizaPedido(this.props.domicilio_envio, this.props.forma_envio, this.props.almacen_recogida, this.props.ruta, this.props.hash);
    }
  };

  render() {
    if (
      this.props.forma_envio.tipo &&
      ((this.props.forma_envio.tipo === "D" && this.props.domicilio_envio.numero_direccion) ||
        (this.props.forma_envio.tipo === "T" && this.props.almacen_recogida.almacen) ||
        (this.props.forma_envio.tipo === "R" && this.props.ruta.almacen))
    ) {
      return <Navigate to="/articulos" />;
    }

    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent">
            <div className="container-indent">
              <div className="container">
                <h1 className="tt-title-subpages noborder">{i18n.t("pedido.piped")}</h1>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-xl-12">
                    <div className="tt-shopcart-wrapper">
                      <FormasEnvio />

                      {this.props.forma_envio.tipo && this.props.forma_envio.tipo === "D" ? <DomiciliosEnvio /> : ""}
                      {this.props.forma_envio.tipo && this.props.forma_envio.tipo === "T" ? <Almacenes /> : ""}
                      {this.props.forma_envio.tipo && this.props.forma_envio.tipo === "R" ? <Rutas /> : ""}

                      <a href="/#" className="btn btn-lg" onClick={this.actualizarPedido}>
                        <span className="icon icon-check_circle"></span>
                        {i18n.t("pedido.pactped")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido: state.pedidos.pedido,
  domicilio_envio: state.domiciliosEnvio.domicilio,
  almacen_recogida: state.almacenes.almacen,
  forma_envio: state.formasEnvio.forma_envio,
  ruta: state.rutas.ruta,
  hash: state.auth.hash,
});

export default connect(mapStateToProps, {
  createMessage,
  actualizaPedido,
})(Pedido);
