import axios from "../axiosConfig";

import { FETCH_SUB_PRESENTACIONES, GET_SUB_PRESENTACIONES, SET_SUB_PRESENTACION } from "../actions/types.js";

import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getSubpresentaciones = (codigo_articulo) => (dispatch, getState) => {
  dispatch({
    type: FETCH_SUB_PRESENTACIONES,
    codigo_articulo: codigo_articulo,
  });
  axios
    .get(`/subpresentaciones/?codigo_articulo=${codigo_articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SUB_PRESENTACIONES,
        payload: res.data,
        codigo_articulo: codigo_articulo,
      });
    })
    .catch((err) => console.log(err));
};

export const setSubpresentacion = (subpresentacion) => (dispatch) => {
  dispatch({
    type: SET_SUB_PRESENTACION,
    payload: subpresentacion,
  });
};
