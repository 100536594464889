import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArticulosByFamily, getArticulos } from "../../actions/articulos";
import { unsetFamiliaSeleccionada, setFamiliaSeleccionada } from "../../actions/familias";
import { añadirFiltro, limpiarFiltros, quitarFiltro } from "../../actions/filtros";
import Spinner from "../layout/Spinner";
import i18n from "../../lang/idiomas";

export class ArticulosFiltros extends Component {
  static propTypes = {
    filtros: PropTypes.object.isRequired,
    filtros_aplicados: PropTypes.array.isRequired,
    articulos_search: PropTypes.string,
    articulos_orden: PropTypes.string,
    getArticulosByFamily: PropTypes.func.isRequired,
    añadirFiltro: PropTypes.func.isRequired,
    limpiarFiltros: PropTypes.func.isRequired,
    quitarFiltro: PropTypes.func.isRequired,
    getArticulos: PropTypes.func.isRequired,
    filtros_aplicables: PropTypes.array.isRequired,
    filtros_claves_aplicables: PropTypes.array.isRequired,
    unsetFamiliaSeleccionada: PropTypes.func.isRequired,
    setFamiliaSeleccionada: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    familias: PropTypes.array.isRequired,
  };

  compararArrais = (a1, a2) => {
    if (!a1 || !a2) return false;
    if (a1.length !== a2.length) return false;
    for (let i = 0, l = a1.length; i < l; i++) {
      if (a1[i] instanceof Array && a2[i] instanceof Array) {
        if (!this.compararArrais(a1[i], a2[i])) return false;
      } else if (a1[i] !== a2[i]) {
        return false;
      }
    }
    return true;
  };

  componentDidUpdate(prevProps) {
    if (!this.compararArrais(this.props.filtros_aplicados, prevProps.filtros_aplicados)) {
      if (this.props.filtros_aplicados.length > 0) {
        this.props.getArticulosByFamily(
          this.props.filtros_aplicados,
          this.props.articulos_pagina,
          this.props.articulos_orden,
          this.props.articulos_search,
          this.props.pedido.domicilio_envio,
          this.props.busqueda_avanzada.avanzada,
          this.props.busqueda_avanzada.codigo_articulo,
          this.props.busqueda_avanzada.codigo_barras,
          this.props.busqueda_avanzada.descripcion,
          this.props.busqueda_avanzada.palabras_clave,
          this.props.busqueda_avanzada.equivalentes
        );
      } else {
        this.props.getArticulos(
          this.props.articulos_search,
          this.props.articulos_pagina,
          this.props.articulos_orden,
          this.props.pedido.domicilio_envio,
          this.props.busqueda_avanzada.avanzada,
          this.props.busqueda_avanzada.codigo_articulo,
          this.props.busqueda_avanzada.codigo_barras,
          this.props.busqueda_avanzada.descripcion,
          this.props.busqueda_avanzada.palabras_clave,
          this.props.busqueda_avanzada.equivalentes
        );
      }
    }
  }

  onClick = (e) => {
    e.preventDefault();

    let _tipo_filtro = e.target.dataset.tipo_filtro;

    if (_tipo_filtro && _tipo_filtro === "estad") {
      let _estadistico = e.target.dataset.estadistico;
      let _familia = e.target.dataset.codigo;
      let _nombre = e.target.dataset.familia;

      let findPos = (elem) => elem.estadistico === _estadistico && elem.familia === _familia;

      if (this.props.filtros_aplicados.findIndex(findPos) === -1) {
        this.props.añadirFiltro({
          estadistico: _estadistico,
          familia: _familia,
          nombre: _nombre,
          tipo: "estad",
        });
        this.props.setFamiliaSeleccionada(_estadistico, _familia, _nombre);
      }
    } else {
      let _clave_estadistica = e.target.dataset.clave_estadistica;
      let _valor_clave = e.target.dataset.valor_clave;
      let _nombre = e.target.dataset.nombre_clave;

      let findPos = (elem) => elem.estadistico === _clave_estadistica && elem.familia === _valor_clave;

      if (this.props.filtros_aplicados.findIndex(findPos) === -1) {
        this.props.añadirFiltro({
          estadistico: _clave_estadistica,
          familia: _valor_clave,
          nombre: _nombre,
          tipo: "clave",
        });
        this.props.setFamiliaSeleccionada(_clave_estadistica, _valor_clave, _nombre);
      }
    }
  };

  eliminarFiltros = (e) => {
    e.preventDefault();
    this.props.limpiarFiltros();
  };

  eliminarFiltro = (e) => {
    e.preventDefault();
    const familia = this.props.familias.filter((elem) => elem.codigo_familia === this.props.familia_seleccionada.codigo_familia)[0];
    if (familia === undefined || familia === null || familia.codigo_padre === undefined || familia.codigo_padre === null) {
      this.props.unsetFamiliaSeleccionada();
      this.props.limpiarFiltros();
    } else {
      const familia_padre = this.props.familias.filter((elem) => elem.codigo_familia === familia.codigo_padre)[0];

      const e = {
        currentTarget: {
          dataset: {
            estadistico: familia_padre.numero_tabla,
            codigo: familia_padre.codigo_familia,
            familia: familia_padre.descripcion,
          },
        },
      };
      let _estadistico = e.currentTarget.dataset.estadistico;
      let _familia = e.currentTarget.dataset.codigo;
      let _nombre = e.currentTarget.dataset.familia;

      this.props.unsetFamiliaSeleccionada();
      this.props.quitarFiltro({
        estadistico: _estadistico,
        familia: _familia,
        nombre: _nombre,
      });

      this.props.anadirFiltro({
        estadistico: _estadistico,
        familia: _familia,
        nombre: _nombre,
        tipo: "estad",
      });
    }
  };

  render() {
    return (
      <Fragment>
        <div className="col-md-4 col-lg-3 col-xl-3 leftColumn aside">
          <Spinner showSpinner={this.props.filtros.isLoading || this.props.filtros_claves_estadisticas.isLoading}></Spinner>
          <div className="tt-btn-col-close">
            <a href="/#">{i18n.t("general.cerrar")}</a>
          </div>
          <div className="tt-collapse open tt-filter-detach-option">
            <div className="tt-collapse-content">
              <div className="filters-mobile">
                <div className="filters-row-select"></div>
              </div>
            </div>
          </div>
          {this.props.filtros_aplicados.length === 0 ? (
            ""
          ) : (
            <div className="tt-collapse open ">
              <h3 className="tt-collapse-title">{i18n.t("articulos.filtact")}</h3>
              <div className="tt-collapse-content">
                <ul className="tt-filter-list">
                  {this.props.filtros_aplicados && this.props.filtros_aplicados.length > 0
                    ? this.props.filtros_aplicados.map((filtro, index) => {
                        let nombre_filtro = filtro.nombre;
                        if (nombre_filtro === "") {
                          let fam_sel = undefined;
                          if (this.props.familias && this.props.familias.length > 0) {
                            fam_sel = this.props.familias.filter((f) => f.numero_tabla === parseInt(filtro.estadistico) && f.codigo_familia === filtro.familia);
                          }
                          if (fam_sel && fam_sel.length === 0 && this.props.filtros) {
                            this.props.filtros.filtros.forEach((f) => {
                              if (f.estadistico === parseInt(filtro.estadistico)) {
                                fam_sel = f.familias.filter((f) => f.numero_tabla === parseInt(filtro.estadistico) && f.codigo_familia === filtro.familia);
                              }
                            });
                          }
                          if (fam_sel && fam_sel.length > 0) {
                            nombre_filtro = fam_sel[0].descripcion;
                          }
                        }

                        return (
                          <li className="active" key={index}>
                            <a href="/#" data-estadistico={filtro.estadistico} data-codigo={filtro.familia} onClick={this.eliminarFiltro}>
                              {nombre_filtro}
                            </a>
                          </li>
                        );
                      })
                    : ""}
                </ul>
                <a href="/#" className="btn-link-02" onClick={this.eliminarFiltros}>
                  {i18n.t("articulos.limptod")}
                </a>
              </div>
            </div>
          )}
          {this.props.filtros && this.props.filtros.filtros && this.props.filtros.filtros.length > 0
            ? this.props.filtros.filtros.map((filtro, index) => {
                if (this.props.filtros_aplicables.some((filtro_aplicable) => filtro_aplicable.estadistico === filtro.estadistico && filtro.familias && filtro.familias.length > 0)) {
                  return (
                    <div key={index} className="tt-collapse open ">
                      <h3 className="tt-collapse-title">{filtro.nombre}</h3>
                      <div className="tt-collapse-content">
                        <ul className="tt-list-row">
                          {filtro.familias.map((familia, index) => {
                            if (
                              this.props.filtros_aplicables.some(
                                (filtro_aplicable) =>
                                  filtro_aplicable.estadistico === filtro.estadistico && filtro_aplicable.familias.some((familia_aplicable) => familia_aplicable === familia.codigo_familia)
                              )
                            ) {
                              return (
                                <li key={index} className="">
                                  <a
                                    href="/#"
                                    data-familia={familia.descripcion}
                                    data-estadistico={familia.numero_tabla}
                                    data-codigo={familia.codigo_familia}
                                    data-tipo_filtro="estad"
                                    onClick={this.onClick}
                                  >
                                    {familia.descripcion}
                                  </a>
                                </li>
                              );
                            } else {
                              return "";
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                } else {
                  return "";
                }
              })
            : ""}

          {this.props.filtros_claves_estadisticas && this.props.filtros_claves_estadisticas.filtros_claves_estadisticas && this.props.filtros_claves_estadisticas.filtros_claves_estadisticas.length > 0
            ? this.props.filtros_claves_estadisticas.filtros_claves_estadisticas.map((filtro, index) => {
                if (this.props.filtros_claves_aplicables.some((filtro_clave_aplicable) => filtro_clave_aplicable.clave_estadistica === filtro.clave_estadistica)) {
                  return (
                    <div key={index} className="tt-collapse open ">
                      <h3 className="tt-collapse-title">{filtro.nombre}</h3>
                      <div className="tt-collapse-content">
                        <ul className="tt-list-row">
                          {filtro.claves && filtro.claves.length > 0
                            ? filtro.claves.map((clave, index) => {
                                if (
                                  this.props.filtros_claves_aplicables.some(
                                    (filtro_aplicable) =>
                                      filtro_aplicable.clave_estadistica === filtro.clave_estadistica &&
                                      filtro_aplicable.claves.some((valor_clave_aplicable) => valor_clave_aplicable === clave.valor_clave)
                                  )
                                ) {
                                  return (
                                    <li key={index} className="">
                                      <a
                                        href="/#"
                                        data-nombre_clave={clave.nombre}
                                        data-clave_estadistica={clave.clave}
                                        data-valor_clave={clave.valor_clave}
                                        data-tipo_filtro="clave"
                                        onClick={this.onClick}
                                      >
                                        {clave.nombre}
                                      </a>
                                    </li>
                                  );
                                } else {
                                  return "";
                                }
                              })
                            : ""}
                        </ul>
                      </div>
                    </div>
                  );
                } else {
                  return "";
                }
              })
            : ""}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filtros: state.articulos.filtros,
  filtros_claves_estadisticas: state.articulos.filtros_claves_estadisticas,
  filtros_aplicados: state.articulos.filtros_aplicados,
  articulos_search: state.listadoArticulos.articulos_search,
  articulos_pagina: state.listadoArticulos.articulos_pagina,
  articulos_orden: state.listadoArticulos.articulos_orden,
  filtros_aplicables: state.listadoArticulos.articulos.filtros_aplicables,
  filtros_claves_aplicables: state.listadoArticulos.articulos.filtros_claves_aplicables,
  auth: state.auth,
  pedido: state.pedidos.pedido,
  busqueda_avanzada: state.listadoArticulos.articulos_advanced_search,
  familias: state.familias.familias,
  familia_seleccionada: state.familias.familia_seleccionada,
});

export default connect(mapStateToProps, {
  getArticulosByFamily,
  añadirFiltro,
  limpiarFiltros,
  quitarFiltro,
  getArticulos,
  unsetFamiliaSeleccionada,
  setFamiliaSeleccionada,
})(ArticulosFiltros);
