import axios from "../axiosConfig";

import { GET_PROVINCIAS, FETCH_PROVINCIAS } from "./types";
import { tokenConfig } from "./auth";

// GET PROVINCIA
export const getProvincias =
  (estado, comunidad_autonoma = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PROVINCIAS,
    });
    axios
      .get(`/provincias/?estado=${estado}&comunidad_autonoma=${comunidad_autonoma}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_PROVINCIAS,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
