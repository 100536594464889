import axios from "../axiosConfig";

import { FETCH_AGRUP_HIJOS, GET_AGRUP_HIJOS, FETCH_AGRUP_COND, GET_AGRUP_COND, SET_HIJO_SELECCIONADO, SET_CLAVE_SELECCIONADA, LIMPIAR_CLAVES_SELECCIONADAS, LIMPIAR_HIJO_SELECCIONADO } from "./types";

import { tokenConfig } from "./auth";

// GET ARTICULOS HIJOS DE AGRUPACION
export const getAgrupacionHijos = (id_articulo) => (dispatch, getState) => {
  dispatch({
    type: FETCH_AGRUP_HIJOS,
  });

  axios
    .get(`/agrupacionhijos?articulo=${id_articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_AGRUP_HIJOS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

// GET CONDICIONES DE AGRUPACION
export const getAgrupacionCondiciones = (id_articulo) => (dispatch, getState) => {
  dispatch({
    type: FETCH_AGRUP_COND,
  });

  axios
    .get(`/agrupacioncondiciones?articulo=${id_articulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_AGRUP_COND,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setClaveSeleccionada = (clave_seleccionada) => (dispatch, getState) => {
  dispatch({
    type: SET_CLAVE_SELECCIONADA,
    payload: clave_seleccionada,
  });
};

export const setHijoSeleccionado = (hijo) => (dispatch, getState) => {
  dispatch({
    type: SET_HIJO_SELECCIONADO,
    payload: hijo,
  });
};

export const limpiarClavesSeleccionadas = () => (dispatch, getState) => {
  dispatch({
    type: LIMPIAR_CLAVES_SELECCIONADAS,
  });
};

export const limpiarHijoSeleccionado = (hijo) => (dispatch, getState) => {
  dispatch({
    type: LIMPIAR_HIJO_SELECCIONADO,
  });
};
