import { SHOW_LOADER, HIDE_LOADER } from "./types";

export const showLoader = () => {
  document.body.classList.remove("loaded");
  return {
    type: SHOW_LOADER,
  };
};

export const hideLoader = () => {
  document.body.classList.add("loaded");
  return {
    type: HIDE_LOADER,
  };
};
