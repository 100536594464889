import axios from "../axiosConfig";

import { FETCH_AVISOS, GET_AVISOS } from "./types";
import { tokenConfig } from "./auth";

// GET AVISOS
export const getAvisos = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_AVISOS,
  });
  axios
    .get(`/avisos`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_AVISOS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
