import axios from "../axiosConfig";

import { FETCH_ALMACENES, GET_ALMACENES, SET_ALMACEN_RECOGIDA } from "../actions/types.js";

import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getAlmacenes = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_ALMACENES,
    payload: true,
  });
  axios
    .get(`/almacenes/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ALMACENES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setAlmacenRecogida = (almacen) => (dispatch) => {
  dispatch({
    type: SET_ALMACEN_RECOGIDA,
    payload: almacen,
  });
};
