import { SHOW_LOADER, HIDE_LOADER } from "../actions/types.js";

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADER:
    case HIDE_LOADER:
    default:
      return state;
  }
}
