import axios from "../axiosConfig";

import { FETCH_TIPOS_PEDIDO, GET_TIPOS_PEDIDO, SET_TIPO_PEDIDO } from "../actions/types.js";

import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getTiposPedido = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_TIPOS_PEDIDO,
  });
  axios
    .get(`/tipospedido/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TIPOS_PEDIDO,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setTipoPedido = (tipo_pedido) => (dispatch) => {
  dispatch({
    type: SET_TIPO_PEDIDO,
    payload: tipo_pedido,
  });
};
