import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showLoader, hideLoader } from "../../actions/loader";

export class Loader extends Component {
  static propTypes = {
    showLoader: PropTypes.func.isRequired,
    hideLoader: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    authIsLoading: PropTypes.bool,
    articulosIsLoading: PropTypes.bool,
    portalIsLoading: PropTypes.bool,
    menusIsLoading: PropTypes.bool,
    listasIsLoading: PropTypes.bool,
    pedidoIsLoading: PropTypes.bool,
  };

  static defaultProps = { isLoading: false, articulosIsLoading: false, listasIsLoading: false, pedidoIsLoading: false };

  componentDidUpdate() {
    const { authIsLoading, portalIsLoading, menusIsLoading, contactoIsLoading, widgetsIsLoading, modularIsLoading } = this.props;
    if (!authIsLoading && !portalIsLoading && !menusIsLoading && !contactoIsLoading && !widgetsIsLoading && !modularIsLoading) {
      this.props.hideLoader();
    } else {
      this.props.showLoader();
    }
  }

  render() {
    return (
      <div id="loader-wrapper">
        <div id="loader">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authIsLoading: state.auth.isLoading,
  portalIsLoading: state.portal.isLoadingPortal,
  menusIsLoading: state.portal.isLoadingMenus,
  contactoIsLoading: state.contacto.isLoading,
  widgetsIsLoading: state.widgets.isLoading,
  modularIsLoading: state.modular.cargando,
});

export default connect(mapStateToProps, {
  showLoader,
  hideLoader,
})(Loader);
