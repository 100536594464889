import axios from "../axiosConfig";

import { GET_OFERTAS_MULTIPLES, FETCH_OFERTAS_MULTIPLES } from "./types";

import { tokenConfig } from "./auth";

export const getOfertasMultiples =
  (codigo_articulo, presentacion = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_OFERTAS_MULTIPLES,
    });
    axios
      .get(`/ofertasMultiples/?codigo_articulo=${codigo_articulo}&presentacion=${presentacion}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_OFERTAS_MULTIPLES,
          payload: res.data,
          codigo_articulo: codigo_articulo,
        });
      })
      .catch((err) => console.log(err));
  };
