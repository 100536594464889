import axios from "../axiosConfig";

import { FETCH_TRANSPORTISTAS, GET_TRANSPORTISTAS, SET_TRANSPORTISTA } from "../actions/types.js";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getTransportistas = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_TRANSPORTISTAS,
  });
  axios
    .get(`/transportistas/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TRANSPORTISTAS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setTransportista = (transportista) => (dispatch) => {
  dispatch({
    type: SET_TRANSPORTISTA,
    payload: transportista,
  });
};
