import axios from "../axiosConfig";

import { FETCH_FORMAS_ENVIO, GET_FORMAS_ENVIO, SET_FORMA_ENVIO, SET_DIRECCION, SET_RUTA_REPARTO, SET_ALMACEN_RECOGIDA } from "./types";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getFormasEnvio = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_FORMAS_ENVIO,
  });
  axios
    .get(`/formasEnvio/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_FORMAS_ENVIO,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setFormaEnvio = (forma_envio) => (dispatch) => {
  dispatch({
    type: SET_FORMA_ENVIO,
    payload: forma_envio,
  });
  //Borramos los posibles datos cruzados al cambiar la forma de envío
  dispatch({
    type: SET_ALMACEN_RECOGIDA,
    payload: {},
  });
  dispatch({
    type: SET_DIRECCION,
    payload: {},
  });
  dispatch({
    type: SET_RUTA_REPARTO,
    payload: {},
  });
};
