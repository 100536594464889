import GA4React from "ga-4-react";
import ReactPixel from "react-facebook-pixel";
import store from "../../store";

export default class AnalyticsEdisa {
  cookiesAnalyticas = () => {
    return !!(
      store.getState().portal.portal &&
      store.getState().portal.portal.parametros_seo &&
      Object.keys(store.getState().portal.portal.parametros_seo).length > 0 &&
      store.getState().portal.cookies.acepta_cookies &&
      store.getState().portal.cookies.analiticas
    );
  };

  inicializaGoogleAnalytics = async (id_google_analytics) => {
    if (!GA4React.isInitialized()) {
      let ga_object = new GA4React(id_google_analytics);
      return await ga_object.initialize(id_google_analytics);
    } else {
      return GA4React.getGA4React();
    }
  };

  inicializaFacebookPixel = (id_facebook_pixel) => {
    const advancedMatching = {};

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };

    ReactPixel.init(id_facebook_pixel, advancedMatching, options);
  };

  registraPagina = (ruta) => {
    if (this.cookiesAnalyticas()) {
      if (store.getState().portal.portal.parametros_seo.id_google_analytics) {
        this.inicializaGoogleAnalytics(store.getState().portal.portal.parametros_seo.id_google_analytics).then((ga) => {
          ga.pageview(ruta);
        });
      }

      if (store.getState().portal.portal.parametros_seo.id_facebook_pixel) {
        this.inicializaFacebookPixel(store.getState().portal.portal.parametros_seo.id_facebook_pixel);
        ReactPixel.pageView(ruta);
      }
    }
  };

  registraEvento = (evento) => {
    if (this.cookiesAnalyticas()) {
      if (store.getState().portal.portal.parametros_seo.id_google_analytics) {
        this.inicializaGoogleAnalytics(store.getState().portal.portal.parametros_seo.id_google_analytics).then((ga) => {
          ga.gtag("event", evento.tipo, {
            category: evento.modulo,
            action: evento.tipo,
            value: evento.valor,
          });
        });
      }

      if (store.getState().portal.portal.parametros_seo.id_facebook_pixel) {
        this.inicializaFacebookPixel();

        let facebook_event = {};

        switch (evento.tipo) {
          case "Search":
            facebook_event = {
              search_string: evento.valor,
            };
            break;
          case "AddToCart":
          case "ViewContent":
            facebook_event = {
              content_ids: evento.params.codigo_articulo,
              content_name: evento.params.descrip_comercial,
              content_type: "product",
              currency: evento.params.divisa,
              value: evento.params.precio,
            };
            break;
          case "Purchase":
          case "InitiateCheckout":
            facebook_event = {
              contents: evento.params.articulos,
              content_type: "product_group",
              currency: evento.params.divisa,
              value: evento.params.precio,
            };
            break;
          case "AddToWishlist":
            facebook_event = {
              content_ids: evento.params.codigo_articulo,
              content_type: "product",
            };
            break;
          default:
            break;
        }

        ReactPixel.trackSingle(store.getState().portal.portal.parametros_seo.id_facebook_pixel, evento.tipo, facebook_event);
      }
    }
  };
}
