import axios from "../axiosConfig";
import { FETCH_EQUIVALENTES, GET_EQUIVALENTES } from "./types";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getArticulosEquivalentes =
  (id_articulo, domicilio_envio = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_EQUIVALENTES,
      codigo_articulo: id_articulo,
    });
    axios
      .get(`/articulos/equivalentes/?codigo_articulo=${id_articulo}&domicilio_envio=${domicilio_envio}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_EQUIVALENTES,
          payload: res.data,
          codigo_articulo: id_articulo,
        });
      })
      .catch((err) => console.log(err));
  };
