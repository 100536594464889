import axios from "../axiosConfig";

import { FETCH_FORMAS_PAGO, GET_FORMAS_PAGO, SET_FORMA_PAGO } from "../actions/types.js";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getFormasPago = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_FORMAS_PAGO,
  });
  const state = getState();
  let url = "/formaspago/";
  let body = {};
  if (state.pedidos.pedido && state.pedidos.pedido.codigo_plantilla) {
    body["pedido"] = state.pedidos.pedido;
    url = url + `?codigo_plantilla=${state.pedidos.pedido.codigo_plantilla}`;
  }
  axios
    .get(url, tokenConfig(getState))
    .then((res) => {
      let result = res;
      console.info({ result });
      dispatch({
        type: GET_FORMAS_PAGO,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const setFormaPago = (forma_pago) => (dispatch) => {
  dispatch({
    type: SET_FORMA_PAGO,
    payload: forma_pago,
  });
};
