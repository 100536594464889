import {
  ERROR_FETCHING_PLANTILLA_DETAIL,
  POST_PEDIDO_PLANTILLA,
  GET_PLANTILLAS,
  FETCH_PLANTILLA_DETAIL,
  FETCH_PLANTILLAS,
  PLANTILLAS_STOP_LOADING,
  GET_PLANTILLAS_DETAILS,
  TOGGLE_PLANTILLAS_DETAILS,
  PLANTILLA_POST_LOADING,
  TOGGLE_POST_PEDIDO_LOADING,
  SET_PLANTILLA_SELECCIONADA,
} from "../actions/types";

const initialState = {
  lista_plantillas: [],
  isLoading: false,
  plantilla_details: null,
  show_plantilla_details: false,
  pedido_creado_de_plantilla: null,
  plantilla_seleccionada: null,
  loadingPost: false,
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case GET_PLANTILLAS:
      return {
        ...state,
        isLoading: false,
        lista_plantillas: action.payload,
        // show_plantilla_details: false,
      };
    case FETCH_PLANTILLAS:
      return {
        ...state,
        lista_plantillas: [],
        plantilla_details: null,
        show_plantilla_details: false,
        isLoading: true,
      };
    case PLANTILLAS_STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case GET_PLANTILLAS_DETAILS:
      return {
        ...state,
        plantilla_details: action.payload,
        isLoading: false,
      };
    case TOGGLE_PLANTILLAS_DETAILS:
      return {
        ...state,
        show_plantilla_details: action.payload,
      };
    case FETCH_PLANTILLA_DETAIL:
      return {
        ...state,
        plantilla_details: null,
        isLoading: true,
      };
    case ERROR_FETCHING_PLANTILLA_DETAIL:
      return {
        ...state,
        plantilla_details: null,
        show_plantilla_details: false,
        loading: false,
      };
    case PLANTILLA_POST_LOADING:
      return {
        ...state,
        loadingPost: action.payload,
      };
    case POST_PEDIDO_PLANTILLA:
      return {
        ...state,
        pedido_creado_de_plantilla: action.payload,
      };
    case SET_PLANTILLA_SELECCIONADA:
      return {
        ...state,
        plantilla_seleccionada: action.payload,
      };
    default:
      return state;
  }
}
