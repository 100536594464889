import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import i18n from "../../lang/idiomas";
import { getLov } from "../../actions/informes";

export class InfromesFiltroLOV extends Component {
  static propTypes = {
    listas_valores: PropTypes.object.isRequired,
    filtro: PropTypes.object.isRequired,
    informe_seleccionado: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.getLov(this.props.informe_seleccionado, this.props.filtro.lista_valores);
  }

  render() {
    if (this.props.filtro.tipo_filtro === "I") {
      return (
        <Fragment>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <select
                  className={"form-control " + this.props.filtro.nombre_filtro_idi}
                  type={this.props.filtro.data_type === "NUMBER" ? "number" : "text"}
                  name={this.props.filtro.nombre_filtro + "_DESDE"}
                  placeholder={this.props.filtro.titulo_columna + " " + i18n.t("general.desde")}
                  required={this.props.filtro.obligatorio === "S"}
                >
                  <option value="">{this.props.filtro.nombre_filtro_idi + " " + i18n.t("general.desde")}</option>
                  {this.props.listas_valores[this.props.filtro.lista_valores] !== undefined
                    ? this.props.listas_valores[this.props.filtro.lista_valores].data.registros.map((registro, index_reg) => {
                        let columna_retorno = this.props.listas_valores[this.props.filtro.lista_valores].data.columna_retorno;

                        let tipo_columna = this.props.listas_valores[this.props.filtro.lista_valores].data.columnas[columna_retorno - 1].tipo;
                        return (
                          <option
                            key={index_reg}
                            value={registro[tipo_columna + columna_retorno]}
                            selected={
                              !Array.isArray(this.props.filtro.valor_inicial_filtro_desde) &&
                              this.props.filtro.valor_inicial_filtro_desde &&
                              this.props.filtro.valor_inicial_filtro_desde === registro[tipo_columna + columna_retorno]
                            }
                          >
                            {this.props.listas_valores[this.props.filtro.lista_valores].data.columnas.map((columna, index) => {
                              if (index + 1 !== Number.parseInt(columna_retorno)) {
                                return registro[columna.tipo + (index + 1)] + " ";
                              }
                              return "";
                            })}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                  className={"form-control " + this.props.filtro.nombre_filtro_idi}
                  type={this.props.filtro.data_type === "NUMBER" ? "number" : "text"}
                  name={this.props.filtro.nombre_filtro + "_HASTA"}
                  placeholder={this.props.filtro.titulo_columna + " " + i18n.t("general.hasta")}
                  required={this.props.filtro.obligatorio === "S"}
                >
                  <option value="">{this.props.filtro.nombre_filtro_idi + " " + i18n.t("general.hasta")}</option>
                  {this.props.listas_valores[this.props.filtro.lista_valores] !== undefined
                    ? this.props.listas_valores[this.props.filtro.lista_valores].data.registros.map((registro, index_reg) => {
                        let columna_retorno = this.props.listas_valores[this.props.filtro.lista_valores].data.columna_retorno;

                        let tipo_columna = this.props.listas_valores[this.props.filtro.lista_valores].data.columnas[columna_retorno - 1].tipo;
                        return (
                          <option
                            key={index_reg}
                            value={registro[tipo_columna + columna_retorno]}
                            selected={
                              !Array.isArray(this.props.filtro.valor_inicial_filtro_hasta) &&
                              this.props.filtro.valor_inicial_filtro_hasta &&
                              this.props.filtro.valor_inicial_filtro_hasta === registro[tipo_columna + columna_retorno]
                            }
                          >
                            {this.props.listas_valores[this.props.filtro.lista_valores].data.columnas.map((columna, index) => {
                              if (index + 1 !== Number.parseInt(columna_retorno)) {
                                return registro[columna.tipo + (index + 1)] + " ";
                              }
                              return "";
                            })}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <select
                  className={"form-control " + this.props.filtro.nombre_filtro_idi}
                  type={this.props.filtro.data_type === "NUMBER" ? "number" : "text"}
                  name={this.props.filtro.nombre_filtro}
                  placeholder={this.props.filtro.titulo_columna}
                  required={this.props.filtro.obligatorio === "S"}
                >
                  <option value="">{this.props.filtro.nombre_filtro_idi}</option>
                  {this.props.listas_valores[this.props.filtro.lista_valores] !== undefined
                    ? this.props.listas_valores[this.props.filtro.lista_valores].data.registros.map((registro, index_reg) => {
                        let columna_retorno = this.props.listas_valores[this.props.filtro.lista_valores].data.columna_retorno;

                        let tipo_columna = this.props.listas_valores[this.props.filtro.lista_valores].data.columnas[columna_retorno - 1].tipo;
                        return (
                          <option
                            key={index_reg}
                            value={registro[tipo_columna + columna_retorno]}
                            selected={
                              !Array.isArray(this.props.filtro.valor_inicial_filtro) &&
                              this.props.filtro.valor_inicial_filtro &&
                              this.props.filtro.valor_inicial_filtro === registro[tipo_columna + columna_retorno]
                            }
                          >
                            {this.props.listas_valores[this.props.filtro.lista_valores].data.columnas.map((columna, index) => {
                              if (index + 1 !== Number.parseInt(columna_retorno)) {
                                return registro[columna.tipo + (index + 1)] + " ";
                              }
                              return "";
                            })}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  informe_seleccionado: state.informes.informe_seleccionado,
  listas_valores: state.informesFiltroLov.listas_valores,
});

export default connect(mapStateToProps, { getLov })(InfromesFiltroLOV);
