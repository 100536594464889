import axios from "../axiosConfig";

import { GET_COMUNIDADES_AUTONOMAS, FETCH_COMUNIDADES_AUTONOMAS } from "./types";
import { tokenConfig } from "./auth";

// GET PROVINCIA
export const getComunidadesAutonomas = (estado) => (dispatch, getState) => {
  dispatch({
    type: FETCH_COMUNIDADES_AUTONOMAS,
  });
  axios
    .get(`/comunidadesautonomas/?estado=${estado}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_COMUNIDADES_AUTONOMAS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
