import { FETCH_TIRAS, GET_TIRAS, FETCH_TIRAS_DET, GET_TIRAS_DET } from "../actions/types.js";

const initialState = {
  tiras: [],
  contenido: [],
  cargando: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TIRAS:
      return {
        ...state,
        cargando: true,
      };
    case FETCH_TIRAS_DET:
      return {
        ...state,
        contenido: [],
        cargando: true,
      };
    case GET_TIRAS:
      return {
        ...state,
        tiras: action.payload,
        cargando: false,
      };
    case GET_TIRAS_DET:
      let contenido_nuevo = JSON.parse(JSON.stringify(state.contenido));

      for (let contenido of action.payload) {
        const indice = contenido_nuevo.findIndex((c) => c.id === contenido.id);

        if (indice !== -1) {
          contenido_nuevo[indice] = contenido;
        } else {
          contenido_nuevo.push(contenido);
        }
      }

      return {
        ...state,
        contenido: contenido_nuevo,
        cargando: false,
      };
    default:
      return state;
  }
}
