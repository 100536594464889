import axios from "../axiosConfig";

import { FETCH_TARIFAS_ENVIO_PORTES, GET_TARIFAS_ENVIO_PORTES } from "./types";
import { tokenConfig } from "./auth";

export const getTarifasEnvioPortes =
  (id_pedido_seleccionado = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_TARIFAS_ENVIO_PORTES,
    });
    axios
      .get(`/tarifasEnvioPortes/?id_pedido_seleccionado=${id_pedido_seleccionado}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_TARIFAS_ENVIO_PORTES,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
