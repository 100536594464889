import axios from "../axiosConfig";
import { GET_ORGANIZACIONES_COMPRAS, GET_ORGANIZACIONES_COMPRAS_SUCCESS, GET_ORGANIZACIONES_COMPRAS_FAILURE, SET_ORGANIZACION_COMPRAS } from "./types";
import { tokenConfig } from "./auth";

// GET DOMICILIOS DE ENVIO DEL CLIENTE
export const getOrganizacionesCompras = () => (dispatch, getState) => {
  dispatch({
    type: GET_ORGANIZACIONES_COMPRAS,
  });
  axios
    .get("/organizacionCompras/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ORGANIZACIONES_COMPRAS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ORGANIZACIONES_COMPRAS_FAILURE,
      });
      console.log(err);
    });
};

export const setOrganizacionCompras = (organizacion_compras) => (dispatch) => {
  dispatch({
    type: SET_ORGANIZACION_COMPRAS,
    payload: organizacion_compras,
  });
};
