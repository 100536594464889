import axios from "../axiosConfig";
import {
  GET_EFACTURAS_COMPRAS,
  GET_EFACTURAS_COMPRAS_SUCCESS,
  GET_EFACTURAS_COMPRAS_FAILURE,
  SUBIR_EFACTURA_COMPRAS,
  SUBIR_EFACTURA_COMPRAS_SUCCESS,
  SUBIR_EFACTURA_COMPRAS_FAILURE,
  GET_EFACTURAS_VENTAS,
  GET_EFACTURAS_VENTAS_SUCCESS,
  GET_EFACTURAS_VENTAS_FAILURE,
  SUBIR_EFACTURA_VENTAS,
  SUBIR_EFACTURA_VENTAS_SUCCESS,
  SUBIR_EFACTURA_VENTAS_FAILURE,
  GET_MAS_EFACTURAS_COMPRAS,
  GET_MAS_EFACTURAS_COMPRAS_SUCCESS,
  GET_MAS_EFACTURAS_COMPRAS_FAILURE,
  GET_MAS_EFACTURAS_VENTAS,
  GET_MAS_EFACTURAS_VENTAS_SUCCESS,
  GET_MAS_EFACTURAS_VENTAS_FAILURE,
} from "./types";
import { tokenConfig } from "./auth";
import { createMessage } from "./messages";

// GET DOMICILIOS DE ENVIO DEL CLIENTE
export const getEfacturasCompras = () => (dispatch, getState) => {
  dispatch({
    type: GET_EFACTURAS_COMPRAS,
  });
  axios
    .get("/efactura/compras/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_EFACTURAS_COMPRAS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_EFACTURAS_COMPRAS_FAILURE,
      });
      console.log(err);
    });
};

export const getMasEfacturasCompras = (url) => (dispatch, getState) => {
  dispatch({
    type: GET_MAS_EFACTURAS_COMPRAS,
  });
  axios
    .get(url, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MAS_EFACTURAS_COMPRAS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_MAS_EFACTURAS_COMPRAS_FAILURE,
      });
      console.log(err);
    });
};

export const subirEfacturasCompras =
  (organizacion_compras, ficheros, numero_remision = "") =>
  (dispatch, getState) => {
    dispatch({
      type: SUBIR_EFACTURA_COMPRAS,
    });

    let body = {
      organizacion_compras: organizacion_compras,
      numero_remision: numero_remision,
      ficheros: ficheros,
    };

    axios
      .post("/efactura/compras/", body, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: SUBIR_EFACTURA_COMPRAS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBIR_EFACTURA_COMPRAS_FAILURE,
        });
        dispatch(createMessage(err.response.data, "error"));
        console.log(err);
      });
  };

export const getEfacturasVentas = () => (dispatch, getState) => {
  dispatch({
    type: GET_EFACTURAS_VENTAS,
  });
  axios
    .get("/efactura/ventas/", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_EFACTURAS_VENTAS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_EFACTURAS_VENTAS_FAILURE,
      });
      console.log(err);
    });
};

export const getMasEfacturasVentas = (url) => (dispatch, getState) => {
  dispatch({
    type: GET_MAS_EFACTURAS_VENTAS,
  });
  axios
    .get(url, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MAS_EFACTURAS_VENTAS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_MAS_EFACTURAS_VENTAS_FAILURE,
      });
      console.log(err);
    });
};

export const subirEfacturasVentas = (ficheros) => (dispatch, getState) => {
  dispatch({
    type: SUBIR_EFACTURA_VENTAS,
  });

  let body = {
    ficheros: ficheros,
  };

  axios
    .post("/efactura/ventas/", body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: SUBIR_EFACTURA_VENTAS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SUBIR_EFACTURA_VENTAS_FAILURE,
      });
      dispatch(createMessage(err.response.data, "error"));
      console.log(err);
    });
};
