import { CHANGE_LANGUAGE, FETCH_LANGUAGES, GET_LANGUAGES } from "./types";
import axios from "../axiosConfig";
import { tokenConfig } from "./auth";

export const changeLanguage = (lang) => (dispatch) => {
  dispatch({
    type: CHANGE_LANGUAGE,
    payload: lang,
  });
};

export const getLanguages = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_LANGUAGES,
  });
  axios
    .get(`/idiomas`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_LANGUAGES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
