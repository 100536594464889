import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export class PieChart extends Component {
  static propTypes = {
    widget: PropTypes.object.isRequired,
  };

  componentDidMount() {}

  componentDidUpdate() {
    window.wookie();
  }

  render() {
    var series = [];
    var plotOptions = {};

    let props = this.props;

    this.props.widget.datos.forEach(function callback(currentValue) {
      var tmpObject = {
        name: currentValue[props.widget.columna_grupo],
        y: currentValue[props.widget.columna_valor],
      };
      series.push(tmpObject);
    });

    if (this.props.widget.tipo === "pie") {
      plotOptions = {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
          },
          showInLegend: true,
        },
      };
    } else {
      plotOptions = {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
          },
          showInLegend: true,
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "75%"],
          size: "110%",
        },
      };
    }

    const options = {
      credits: {
        enabled: false,
      },
      colors: ["#A7AABD", "#B4485D", "#F07D8F", "#528B88", "#EEE8A9", "#D4A418", "#426BB9", "#424656"],
      chart: {
        type: this.props.widget.tipo === "semicircle" ? "pie" : this.props.widget.tipo,
      },
      plotOptions: plotOptions,
      title: {
        text: this.props.widget.titulo,
      },
      subtitle: {
        text: this.props.widget.subtitulo_grafico,
      },
      series: [
        {
          name: this.props.widget.titulo_eje_y,
          data: series,
        },
      ],
    };
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PieChart);
