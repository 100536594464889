import axios from "../axiosConfig";

import { FETCH_PEDIDO_HABITUAL, GET_PEDIDO_HABITUAL } from "./types";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getPedidoHabitual =
  (domicilio_envio = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_PEDIDO_HABITUAL,
      payload: true,
    });
    axios
      .get(`/articulos/habitualCheckout/?domicilio_envio=${domicilio_envio}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_PEDIDO_HABITUAL,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
