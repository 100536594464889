import axios from "../axiosConfig";

import { FETCH_TIPOS_NIF, GET_TIPOS_NIF } from "./types";
import { tokenConfig } from "./auth";

// GET PROVINCIA
export const getTiposNif = (estado) => (dispatch, getState) => {
  dispatch({
    type: FETCH_TIPOS_NIF,
  });
  axios
    .get(`/usuario/tiposnif/?estado=${estado}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TIPOS_NIF,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
