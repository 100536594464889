import { SET_OPEN, SET_CLOSE, SET_LINEA_PED, SET_CARNET_PROFESIONAL, NEW_RECIPE } from "./types.js";

export const openModal = () => (dispatch) => {
  dispatch({
    type: SET_OPEN,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: SET_CLOSE,
  });
};

export const setLinea = (linea, origen) => (dispatch) => {
  dispatch({
    type: SET_LINEA_PED,
    payload: { linea, origen },
  });
};

export const setCarnetProfesional = (linea, origen) => (dispatch) => {
  dispatch({
    type: SET_CARNET_PROFESIONAL,
    payload: { linea, origen },
  });
};

export const nuevaReceta = (linea, origen) => (dispatch) => {
  dispatch({
    type: NEW_RECIPE,
    payload: { linea, origen },
  });
};
