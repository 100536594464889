import axios from "../axiosConfig";

import { FETCH_BANNERS, GET_BANNERS, FETCH_IMG_BANNER, GET_IMG_BANNER } from "./types";
import { tokenConfig } from "./auth";

// GET BANNERS
export const getBanners = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_BANNERS,
  });
  axios
    .get(`/banners`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_BANNERS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getImagenBanner = (id_banner, numero_imagen) => (dispatch, getState) => {
  dispatch({
    type: FETCH_IMG_BANNER,
    id_banner: id_banner,
    numero_imagen: numero_imagen,
  });

  axios
    .get(`/bannersimg?banner=${id_banner}&imagen=${numero_imagen}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_IMG_BANNER,
        payload: res.data,
        id_banner: id_banner,
        numero_imagen: numero_imagen,
      });
    })
    .catch((err) => console.log(err));
};
