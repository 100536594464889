import { FETCH_RUTAS, GET_RUTAS, SET_RUTA_REPARTO, GET_RUTAS_DOM, FETCH_RUTAS_DOM } from "../actions/types.js";

const initialState = {
  rutas: [],
  ruta: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RUTAS:
      return {
        ...state,
        rutas: [],
        ruta: {},
        isLoading: true,
      };
    case GET_RUTAS:
      return {
        ...state,
        rutas: action.payload,
        isLoading: false,
      };
    case SET_RUTA_REPARTO:
      return {
        ...state,
        ruta: action.payload,
      };
    case FETCH_RUTAS_DOM:
      return {
        ...state,
        rutas: [],
        ruta: {},
        isLoading: true,
      };
    case GET_RUTAS_DOM:
      return {
        ...state,
        rutas: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
