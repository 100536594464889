import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSubpresentaciones, setSubpresentacion } from "../../../actions/subpresentaciones";

export class Subpresentaciones extends Component {
  static propTypes = {
    codigo_articulo: PropTypes.string.isRequired,
    subpresentaciones: PropTypes.object.isRequired,
    subpresentacion: PropTypes.object.isRequired,
    getSubpresentaciones: PropTypes.func.isRequired,
    setSubpresentacion: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.codigo_articulo) {
      this.props.getSubpresentaciones(this.props.codigo_articulo);
    }
  }

  onclick = (e) => {
    e.preventDefault();
    if (e.target.value !== "") {
      const subpresentacion = this.props.subpresentaciones[this.props.codigo_articulo].filter((subpresentacion) => subpresentacion.subpresentacion === e.target.value);

      this.props.setSubpresentacion(subpresentacion[0]);
    }
  };

  componentDidUpdate(prevProps) {
    window.wookie();
  }

  render() {
    return (
      <Fragment>
        {this.props.subpresentaciones && this.props.subpresentaciones[this.props.codigo_articulo] && this.props.subpresentaciones[this.props.codigo_articulo].length > 0 ? (
          <div className="row subpresentaciones">
            <div className="col">
              {this.props.oculta_titulo && this.props.oculta_titulo === true ? "" : <div className="titulo-subpresentaciones">Subpresentaciones</div>}

              {this.props.subpresentaciones[this.props.codigo_articulo].map((subpresentacion, index) => {
                return (
                  <button
                    key={index}
                    className={
                      this.props.subpresentacion &&
                      subpresentacion.codigo_articulo === this.props.subpresentacion.codigo_articulo &&
                      subpresentacion.subpresentacion === this.props.subpresentacion.subpresentacion
                        ? "btn btn-small btn-primary"
                        : "btn btn-small btn-border"
                    }
                    name="subpresentacion"
                    value={subpresentacion.subpresentacion}
                    onClick={this.onclick}
                  >
                    {subpresentacion.subpresentacion}
                  </button>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  subpresentaciones: state.subpresentaciones.subpresentaciones,
  subpresentacion: state.subpresentaciones.subpresentacion,
});

export default connect(mapStateToProps, {
  getSubpresentaciones,
  setSubpresentacion,
})(Subpresentaciones);
