import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import { GENERA_PAGO_PASARELA, GENERA_PAGO_PASARELA_SUCCESS, GET_PEDIDO_ACTIVO } from "./types";

export const generaPagoCardnet = (token, id_pedido) => (dispatch, getState) => {
  dispatch({
    type: GENERA_PAGO_PASARELA,
  });

  let body = { token: token, id_pedido: id_pedido };
  axios
    .post(`/cardnet/generapago`, body, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GENERA_PAGO_PASARELA_SUCCESS,
      });
      dispatch({
        type: GET_PEDIDO_ACTIVO,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
