import { FETCH_SUB_PRESENTACIONES, GET_SUB_PRESENTACIONES, SET_SUB_PRESENTACION } from "../actions/types.js";

const initialState = {
  subpresentaciones: {},
  subpresentacion: {},
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  let lista_subpresentaciones;
  switch (action.type) {
    case FETCH_SUB_PRESENTACIONES:
      lista_subpresentaciones = JSON.parse(JSON.stringify(state.subpresentaciones));
      lista_subpresentaciones[action.codigo_articulo] = [];
      return {
        ...state,
        subpresentaciones: lista_subpresentaciones,
        isLoading: true,
      };
    case GET_SUB_PRESENTACIONES:
      lista_subpresentaciones = JSON.parse(JSON.stringify(state.subpresentaciones));

      lista_subpresentaciones[action.codigo_articulo] = action.payload;
      return {
        ...state,
        subpresentaciones: lista_subpresentaciones,
        isLoading: false,
      };
    case SET_SUB_PRESENTACION:
      return {
        ...state,
        subpresentacion: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
