import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getDescripciones } from "../../actions/descripcion";
import i18n from "../../lang/idiomas";

export class Descripcion extends Component {
  state = {};

  static propTypes = {
    descripciones: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getDescripciones: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.descripciones[this.props.codigo_articulo] === undefined) {
      this.props.getDescripciones(this.props.codigo_articulo, this.props.auth.hash != null ? this.props.auth.hash : "");
    }
  }

  render() {
    if (this.props.descripciones[this.props.codigo_articulo] === undefined || this.props.descripciones[this.props.codigo_articulo].isLoading) {
      return (
        <Fragment>
          <img className="loader-descripcion" src="assets/img/loader.gif" data-src="assets/img/loader.gif" alt="Cargando..." />
          <span>{i18n.t("general.cargando")}...</span>
        </Fragment>
      );
    } else if (this.props.descripciones[this.props.codigo_articulo].error) {
      return (
        <Fragment>
          <span>{i18n.t("general.desnodis")}</span>
        </Fragment>
      );
    } else if (this.props.descripciones[this.props.codigo_articulo]) {
      return (
        <Fragment>
          <span>{this.props.descripciones[this.props.codigo_articulo].data.descrip_comercial ? this.props.descripciones[this.props.codigo_articulo].data.descrip_comercial : ""}</span>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  descripciones: state.descripcion.descripciones,
  auth: state.auth,
});

export default connect(mapStateToProps, { getDescripciones })(Descripcion);
