import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import { FETCH_MENU_FAMILIAS, GET_MENU_FAMILIAS, FETCH_IMAGENES_FAMILIAS, GET_IMAGENES_FAMILIAS, SET_FAMILIA_SELECCIONADA, UNSET_FAMILIA_SELECCIONADA } from "./types";

// GET MENU INFORMES
export const getMenuFamilias = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_MENU_FAMILIAS,
  });

  let config = tokenConfig(getState);

  axios
    .get(`/familias/`, config)
    .then((res) => {
      dispatch({
        type: GET_MENU_FAMILIAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getImagenesFamilias =
  (numero_tabla = "", codigo_familia = "", codigo_imagen = "", principal = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_IMAGENES_FAMILIAS,
    });

    let config = tokenConfig(getState);
    axios
      .get(`/familiasimagenes/?estadistico=${numero_tabla}&familia=${codigo_familia}&imagen=${codigo_imagen}&principal=${principal}`, config)
      .then((res) => {
        dispatch({
          type: GET_IMAGENES_FAMILIAS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const setFamiliaSeleccionada = (estadistico, codigo_familia, nombre) => (dispatch, getState) => {
  dispatch({
    type: SET_FAMILIA_SELECCIONADA,
    payload: {
      estadistico: estadistico,
      codigo_familia: codigo_familia,
      nombre: nombre,
    },
  });
};

export const unsetFamiliaSeleccionada = () => (dispatch, getState) => {
  dispatch({
    type: UNSET_FAMILIA_SELECCIONADA,
  });
};
