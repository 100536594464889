import axios from "../axiosConfig";
import { FETCH_RELACIONADOS, GET_RELACIONADOS } from "./types";
import { tokenConfig } from "./auth";

// GET ARTICULOS
export const getArticulosRelacionados =
  (id_articulo, domicilio_envio = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_RELACIONADOS,
      payload: true,
    });
    axios
      .get(`/articulos/relacionados/?id_articulo=${id_articulo}&domicilio_envio=${domicilio_envio}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_RELACIONADOS,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };
