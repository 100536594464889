import { CLEAR_MESSAGE, SET_MESSAGE } from "./types";

export const createMessage = (msg, status) => {
  return {
    type: SET_MESSAGE,
    payload: { msg, status },
  };
};

export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE,
  };
};
