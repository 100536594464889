import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAvisos } from "../../actions/avisos";

export class Avisos extends Component {
  static propTypes = {
    getAvisos: PropTypes.func.isRequired,
    avisos: PropTypes.array.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    if (this.props.user !== prevProps.user && !this.props.isLoading) {
      this.props.getAvisos();
    }
  }

  render() {
    return (
      <div className="avisos">
        {this.props.avisos && this.props.avisos.length > 0 ? (
          <div className="container">
            {this.props.avisos.map((aviso, index) => {
              return (
                <div key={index} className="alert alert-warning alert-dismissible fade show" role="alert">
                  {aviso.texto_aviso}
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  avisos: state.avisos.avisos,
  user: state.auth.user,
  isLoading: state.avisos.isLoading,
});

export default connect(mapStateToProps, { getAvisos })(Avisos);
