import { SET_LISTA_VALORES } from "../actions/types.js";

const initialState = {
  listas_valores: {},
};

export default function reducer(state = initialState, action) {
  let lista_listas_valores;
  switch (action.type) {
    case SET_LISTA_VALORES:
      lista_listas_valores = state.listas_valores;

      let payload = action.payload;

      payload["registros"] = Array.isArray(action.payload["registros"]) ? action.payload["registros"] : [action.payload["registros"].registro];

      lista_listas_valores[action.lista_valores] = {
        data: payload,
      };
      return {
        ...state,
        listas_valores: { ...state.listas_valores, ...lista_listas_valores },
        isLoading: true,
      };
    default:
      return state;
  }
}
