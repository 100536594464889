import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getImagenBanner } from "../../actions/banners";

export class ImagenBanner extends Component {
  static propTypes = {
    banners: PropTypes.array.isRequired,
    getImagenBanner: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.id_banner && this.props.numero_imagen) {
      let findPos = (elem) => elem.id_banner === this.props.id_banner;
      let pos = this.props.banners.findIndex(findPos);

      if (pos !== -1) {
        let findPos2 = (elem) => elem.orden === this.props.numero_imagen;
        let pos2 = this.props.banners[pos].imagenes.findIndex(findPos2);

        if (pos2 !== -1) {
          const imagen = this.props.banners[pos].imagenes[pos2].imagen;
          const cargando = this.props.banners[pos].imagenes[pos2].cargando;

          if ((imagen === undefined || imagen === null || imagen === "") && !cargando) {
            this.props.getImagenBanner(this.props.id_banner, this.props.numero_imagen);
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.id_banner !== prevProps.id_banner || this.props.numero_imagen !== prevProps.numero_imagen) {
      this.props.getImagenBanner(this.props.id_banner, this.props.numero_imagen);
    }
  }

  render() {
    let findPos = (elem) => elem.id_banner === this.props.id_banner;
    let pos = this.props.banners.findIndex(findPos);

    let findPos2 = (elem) => elem.orden === this.props.numero_imagen;
    let pos2 = this.props.banners[pos].imagenes.findIndex(findPos2);

    const imagen = this.props.banners[pos].imagenes[pos2].imagen;
    return <Fragment>{imagen && imagen !== "" ? <img src={"data:image/png;base64, " + imagen} data-src={"data:image/png;base64, " + imagen} alt="" /> : ""}</Fragment>;
  }
}

const mapStateToProps = (state) => ({
  banners: state.banners.banners,
});

export default connect(mapStateToProps, {
  getImagenBanner,
})(ImagenBanner);
