import axios from "../axiosConfig";
import { tokenConfig } from "./auth";

import { GET_DESCRIPCIONES, GET_DESCRIPCIONES_SUCCESS, GET_DESCRIPCIONES_FAIL } from "./types";

// GET ARTICULO
export const getDescripciones =
  (codigo_articulo, hash = "") =>
  (dispatch, getState) => {
    dispatch({
      type: GET_DESCRIPCIONES,
      codigo_articulo: codigo_articulo,
    });
    axios
      .get(`/articulos/descripcion/?articulo=${codigo_articulo}&inv=${hash}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_DESCRIPCIONES_SUCCESS,
          codigo_articulo: codigo_articulo,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_DESCRIPCIONES_FAIL,
          codigo_articulo: codigo_articulo,
        });
      });
  };
