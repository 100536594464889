import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getGaleria } from "../../actions/galeria";
import Slider from "react-slick";
import Imagen from "./Imagen";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

export class Galeria extends Component {
  state = {
    nav1: null,
    nav2: null,
    is_open: false,
  };

  static propTypes = {
    galeria: PropTypes.object.isRequired,
    getGaleria: PropTypes.func.isRequired,
    codigo_articulo: PropTypes.string.isRequired,
  };

  componentDidMount() {
    if (this.props.galeria[this.props.codigo_articulo] === undefined) {
      this.props.getGaleria(this.props.codigo_articulo);
    }

    this.setState({ nav1: null });
    this.setState({ nav2: null });
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();

    if (prevProps.codigo_articulo !== this.props.codigo_articulo) {
      if (this.props.galeria[this.props.codigo_articulo] === undefined) {
        this.props.getGaleria(this.props.codigo_articulo);
      }
    }

    if (
      this.props.galeria[this.props.codigo_articulo] &&
      this.props.galeria[this.props.codigo_articulo].data.length > 0 &&
      this.state.nav1 === null &&
      this.state.nav2 === null &&
      this.slider1 &&
      this.slider2
    ) {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  cerrarModal = (e) => {
    e.preventDefault();
    this.setState({ is_open: false });
  };

  abrirModal = (e) => {
    e.preventDefault();
    this.setState({ is_open: true });
  };

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
      },
      overlay: {
        "z-index": "10",
      },
    };

    if (this.props.galeria[this.props.codigo_articulo]) {
      return (
        <Fragment>
          <ReactModal isOpen={this.state.is_open} onRequestClose={this.cerrarModal} style={customStyles}>
            {this.props.galeria[this.props.codigo_articulo].data.length > 1 ? (
              <Slider asNavFor={this.state.nav2} ref={(slider) => (this.slider1 = slider)} slidesToShow={1} slidesToScroll={1} lazyLoad={true} infinite={true}>
                {this.props.galeria[this.props.codigo_articulo].data.map((imagen) => (
                  <div key={"div_img_" + imagen.id}>
                    <Imagen codigo_articulo={imagen.articulo} principal="N" numero_imagen={imagen.numero_imagen} />
                  </div>
                ))}
              </Slider>
            ) : (
              <Imagen codigo_articulo={this.props.codigo_articulo} numero_imagen={1} principal="S" />
            )}
          </ReactModal>
          <div className="tt-product-vertical-layout">
            <div className="tt-product-single-img">
              <div>
                {this.props.galeria[this.props.codigo_articulo].data.length > 1 ? (
                  <Fragment>
                    <button onClick={this.abrirModal} className="tt-btn-zomm tt-top-right">
                      <i className="icon-f-86"></i>
                    </button>
                    <Slider asNavFor={this.state.nav2} ref={(slider) => (this.slider1 = slider)} slidesToShow={1} slidesToScroll={1} lazyLoad={true} infinite={true}>
                      {this.props.galeria[this.props.codigo_articulo].data.map((imagen) => (
                        <div key={"div_img_" + imagen.id}>
                          <Imagen codigo_articulo={imagen.articulo} principal="N" numero_imagen={imagen.numero_imagen} />
                        </div>
                      ))}
                    </Slider>
                  </Fragment>
                ) : (
                  <Imagen codigo_articulo={this.props.codigo_articulo} principal="S" numero_imagen={1} />
                )}
              </div>
            </div>
            <div className="tt-product-single-carousel-vertical">
              {this.props.galeria[this.props.codigo_articulo].data.length > 1 ? (
                <Slider
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  slidesToShow={this.props.galeria[this.props.codigo_articulo].data.length}
                  initialSlide={0}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  vertical={true}
                  lazyLoad={true}
                  infinite={true}
                >
                  {this.props.galeria[this.props.codigo_articulo].data.map((imagen) => (
                    <div key={imagen.id}>
                      <Imagen codigo_articulo={imagen.articulo} principal="N" numero_imagen={imagen.numero_imagen} />
                    </div>
                  ))}
                </Slider>
              ) : (
                ""
              )}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  galeria: state.galeria.galeria,
});

export default connect(mapStateToProps, {
  getGaleria,
})(Galeria);
