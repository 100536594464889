import { GET_MIGAS_ARTICULO, FETCH_MIGAS_ARTICULO } from "../actions/types.js";

const initialState = {
  migas: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MIGAS_ARTICULO:
      return {
        ...state,
        migas: [],
      };
    case GET_MIGAS_ARTICULO:
      return {
        ...state,
        migas: action.payload,
      };
    default:
      return state;
  }
}
