import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getMigasArticulo } from "../../actions/migas";
import { limpiarFiltros, añadirFiltro } from "../../actions/filtros";
import { withRouter } from "../../helpers/funciones";
import { setFamiliaSeleccionada } from "../../actions/familias";

export class Migas extends Component {
  static propTypes = {
    migas: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.getMigasArticulo(this.props.id_articulo, this.props.auth.hash != null ? this.props.auth.hash : "");
  }

  onClick = (e) => {
    e.preventDefault();
    let _estadistico = e.target.dataset.estadistico;
    let _familia = e.target.dataset.codigo;
    let _nombre = e.target.dataset.familia;

    this.props.limpiarFiltros();

    this.props.añadirFiltro({
      estadistico: _estadistico,
      familia: _familia,
      nombre: _nombre,
      tipo: "estad",
    });
    this.props.setFamiliaSeleccionada(_estadistico, _familia, _nombre);

    this.props.history.push("/articulos");
  };

  render() {
    let reversed = this.props.migas.reverse();
    return (
      <Fragment>
        <div className="tt-breadcrumb">
          <div className="container">
            <ul>
              {reversed.map((miga) => (
                <li key={miga.estadistico + miga.familia}>
                  <a href="#" onClick={this.onClick} data-codigo={miga.familia} data-estadistico={miga.estadistico} data-familia={miga.descripcion}>
                    {miga.descripcion}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  migas: state.migas.migas,
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, {
    getMigasArticulo,
    limpiarFiltros,
    añadirFiltro,
    setFamiliaSeleccionada,
  })(Migas)
);
