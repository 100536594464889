import {
  FETCH_AGRUP_HIJOS,
  GET_AGRUP_HIJOS,
  FETCH_AGRUP_COND,
  GET_AGRUP_COND,
  SET_HIJO_SELECCIONADO,
  SET_CLAVE_SELECCIONADA,
  LIMPIAR_CLAVES_SELECCIONADAS,
  LIMPIAR_HIJO_SELECCIONADO,
} from "../actions/types.js";

const initialState = {
  cargando_hijos: false,
  cargando_condiciones: false,
  hijos: [],
  condiciones: [],
  claves_seleccionadas: [],
  hijo_seleccionado: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AGRUP_HIJOS:
      return {
        ...state,
        cargando_hijos: true,
      };
    case GET_AGRUP_HIJOS:
      return {
        ...state,
        hijos: action.payload,
        cargando_hijos: false,
      };
    case FETCH_AGRUP_COND:
      return {
        ...state,
        cargando_condiciones: true,
      };
    case GET_AGRUP_COND:
      return {
        ...state,
        condiciones: action.payload,
        cargando_condiciones: false,
      };
    case SET_CLAVE_SELECCIONADA:
      let claves_filtradas = state.claves_seleccionadas.filter((clave) => clave.clave !== action.payload.clave);

      claves_filtradas = [...claves_filtradas, action.payload];
      return {
        ...state,
        claves_seleccionadas: claves_filtradas,
        cargando_condiciones: false,
      };
    case SET_HIJO_SELECCIONADO:
      return {
        ...state,
        hijo_seleccionado: action.payload,
        cargando_condiciones: false,
      };
    case LIMPIAR_CLAVES_SELECCIONADAS:
      return {
        ...state,
        claves_seleccionadas: [],
        cargando_condiciones: false,
      };
    case LIMPIAR_HIJO_SELECCIONADO:
      return {
        ...state,
        hijo_seleccionado: {},
        cargando_condiciones: false,
      };
    default:
      return state;
  }
}
