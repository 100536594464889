import { GET_TEXTOS_VENTAS, FETCH_TEXTOS_VENTAS } from "../actions/types.js";

const initialState = {
  textos_ventas: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEXTOS_VENTAS:
      return {
        ...state,
        textos_ventas: action.payload,
        isLoading: false,
      };
    case FETCH_TEXTOS_VENTAS:
      return {
        ...state,
        textos_ventas: [],
        isLoading: true,
      };
    default:
      return state;
  }
}
