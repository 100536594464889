import axios from "../axiosConfig";

import { FETCH_MODULAR, GET_MODULAR, FETCH_MODULAR_DET, GET_MODULAR_DET } from "./types";
import { tokenConfig } from "./auth";

// GET MODULAR
export const getModular = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_MODULAR,
  });
  axios
    .get(`/modular`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MODULAR,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getModularDet = (modulo) => (dispatch, getState) => {
  dispatch({
    type: FETCH_MODULAR_DET,
  });

  axios
    .get(`/modulardet?modulo=${modulo}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MODULAR_DET,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
