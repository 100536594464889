import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import es_ES from "./es_ES/es_ES";
import pt_PT from "./pt_PT/pt_PT";
import en_US from "./en_US/en_US";
import gl_ES from "./gl_ES/gl_ES";

const userLang = navigator.language || navigator.userLanguage;
const defaultLocale = userLang.substring(0, 2);
i18n.use(initReactI18next).init({
  lng: defaultLocale,
  fallbackLng: "es",
  resources: {
    es: es_ES,
    pt: pt_PT,
    en: en_US,
    gl: gl_ES,
  },
});

export default i18n;
