import { FETCH_FORMAS_ENVIO, GET_FORMAS_ENVIO, SET_FORMA_ENVIO } from "../actions/types.js";

const initialState = {
  formas_envio: [],
  forma_envio: {},
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FORMAS_ENVIO:
      return {
        ...state,
        formas_envio: [],
        forma_envio: {},
        isLoading: true,
      };
    case GET_FORMAS_ENVIO:
      return {
        ...state,
        formas_envio: action.payload,
        isLoading: false,
      };
    case SET_FORMA_ENVIO:
      return {
        ...state,
        forma_envio: action.payload,
      };
    default:
      return state;
  }
}
