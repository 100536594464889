import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { limpiarFiltros, añadirFiltro } from "../../actions/filtros";
import { setBusqueda } from "../../actions/articulos";
import { withRouter } from "../../helpers/funciones";
import { getMenuFamilias, getImagenesFamilias, setFamiliaSeleccionada, unsetFamiliaSeleccionada } from "../../actions/familias";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

export class FamiliasMenu extends Component {
  static propTypes = {
    familias: PropTypes.array.isRequired,
    filtros_aplicados: PropTypes.array.isRequired,
    limpiarFiltros: PropTypes.func.isRequired,
    setFamiliaSeleccionada: PropTypes.func.isRequired,
    añadirFiltro: PropTypes.func.isRequired,
    usar_imagenes: PropTypes.bool,
    familia_seleccionada: PropTypes.object.isRequired,
    unsetFamiliaSeleccionada: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.familias === undefined || this.props.familias.length === 0) {
      this.props.getMenuFamilias();
    }

    if (this.props.usar_imagenes && this.props.usar_imagenes) {
      if (this.props.imagenes_familias === undefined || this.props.imagenes_familias.length === 0) {
        this.props.getImagenesFamilias();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
  }

  anadirFiltro = (e) => {
    let _estadistico = e.currentTarget.dataset.estadistico;
    let _familia = e.currentTarget.dataset.codigo;
    let _nombre = e.currentTarget.dataset.familia;

    this.props.limpiarFiltros();

    this.props.setBusqueda("");

    this.props.setFamiliaSeleccionada(_estadistico, _familia, _nombre);

    this.props.añadirFiltro({
      estadistico: _estadistico,
      familia: _familia,
      nombre: _nombre,
      tipo: "estad",
    });

    if (this.props.router && this.props.router.location.pathname !== "/articulos") {
      this.props.router.navigate("/articulos");
    }
  };

  onClick = (e) => {
    e.preventDefault();
    this.anadirFiltro(e);
  };

  onClickHijo = (e) => {
    e.preventDefault();
    this.anadirFiltro(e);
    window.cerrar_hijos(e.target.parentNode);
  };

  preventClick(e) {
    e.preventDefault();

    window.abrir_hijos(e.target.parentNode);
  }

  desplegarFamilias = (e) => {
    e.preventDefault();
    document.getElementsByClassName("familias-primer-nivel")[0].classList.toggle("visible");
  };

  pintarHijos(familia_padre) {
    var className_dropdown = "";
    var className_megamenu = "";
    if (familia_padre.nivel && familia_padre.nivel !== 1) {
      className_dropdown = "dropdown-menu tt-nivel-" + (familia_padre.nivel - 1);
      className_megamenu = "tt-megamenu-submenu tt-submenu-" + (familia_padre.nivel - 1);
    } else {
      className_dropdown = "dropdown-menu";
      className_megamenu = "tt-megamenu-submenu";
    }
    return (
      <div className={className_dropdown}>
        <div className="row tt-col-list">
          <div className="col">
            <ul className={className_megamenu}>
              {this.props.familias.map((hijo, hijo_index) => {
                if (hijo.codigo_padre === familia_padre.codigo_familia) {
                  return (
                    <li key={hijo_index}>
                      <a href="/#" data-familia={hijo.descripcion} data-estadistico={hijo.numero_tabla} data-codigo={hijo.codigo_familia} onClick={this.onClickHijo}>
                        {hijo.descripcion}
                      </a>
                      {this.props.familias === undefined
                        ? ""
                        : this.props.familias.filter((fam) => fam.codigo_padre === hijo.codigo_familia).reduce((accumulator, currentValue) => accumulator + 1, 0) > 0
                        ? this.pintarHijos(hijo)
                        : ""}
                    </li>
                  );
                }
                return "";
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  limpiarFamilia = () => {
    const familia = this.props.familias.filter((elem) => elem.codigo_familia === this.props.familia_seleccionada.codigo_familia)[0];
    if (familia === undefined || familia === null || familia.codigo_padre === undefined || familia.codigo_padre === null) {
      this.props.unsetFamiliaSeleccionada();
      this.props.limpiarFiltros();
    } else {
      const familia_padre = this.props.familias.filter((elem) => elem.codigo_familia === familia.codigo_padre)[0];

      const e = {
        currentTarget: {
          dataset: {
            estadistico: familia_padre.numero_tabla,
            codigo: familia_padre.codigo_familia,
            familia: familia_padre.descripcion,
          },
        },
      };

      this.anadirFiltro(e);
    }
  };

  render() {
    if (this.props.usar_imagenes === undefined || this.props.usar_imagenes === null || !this.props.usar_imagenes) {
      return (
        <Fragment>
          <header className="menu-familias">
            <div className="tt-color-scheme-01">
              <div className="container">
                <div className="tt-header-holder">
                  <div className="tt-obj-menu">
                    <div className="tt-desctop-parent-menu tt-parent-box">
                      <div className="tt-desctop-menu tt-hover-02">
                        <nav>
                          <div className="toggle-familias-div">
                            <span className="toggle-familias-button" onClick={this.desplegarFamilias}>
                              CATEGORÍAS <i className="icon-e-01"></i>
                            </span>
                          </div>
                          <ul className="familias-primer-nivel">
                            {this.props.familias === undefined
                              ? ""
                              : this.props.familias.map((familia, index) => {
                                  if (familia.nivel === 1) {
                                    return (
                                      <li
                                        key={index}
                                        className={
                                          this.props.familias.filter((fam) => fam.codigo_padre === familia.codigo_familia).reduce((accumulator, currentValue) => accumulator + 1, 0) > 0
                                            ? "dropdown tt-megamenu tt-submenu"
                                            : "dropdown tt-megamenu "
                                        }
                                      >
                                        <a
                                          href=""
                                          data-familia={familia.descripcion}
                                          data-estadistico={familia.numero_tabla}
                                          data-codigo={familia.codigo_familia}
                                          onClick={familia.ultimo_nivel === "S" ? this.onClick : this.preventClick}
                                        >
                                          {familia.descripcion}{" "}
                                          {this.props.familias.filter((fam) => fam.codigo_padre === familia.codigo_familia).reduce((accumulator, currentValue) => accumulator + 1, 0) > 0 ? (
                                            <i className="icon-e-01"></i>
                                          ) : (
                                            ""
                                          )}
                                        </a>
                                        {this.props.familias.filter((fam) => fam.codigo_padre === familia.codigo_familia).reduce((accumulator, currentValue) => accumulator + 1, 0) > 0
                                          ? this.pintarHijos(familia)
                                          : ""}
                                      </li>
                                    );
                                  }
                                  return "";
                                })}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Fragment>
      );
    } else {
      if (this.props.familias && this.props.familias.length > 0) {
        let familias_imagenes = this.props.familias;
        if (this.props.familia_seleccionada && Object.keys(this.props.familia_seleccionada).length > 0) {
          familias_imagenes = this.props.familias.filter((elem) => elem.codigo_padre === this.props.familia_seleccionada.codigo_familia);
          if (this.props.familia_seleccionada.nombre === "") {
            //Si no tenemos el nombre de la familia seleccionada lo recuperamos
            let fam_sel = undefined;
            if (this.props.familias && this.props.familias.length > 0) {
              fam_sel = this.props.familias.filter(
                (f) => f.numero_tabla === parseInt(this.props.familia_seleccionada.estadistico) && f.codigo_familia === this.props.familia_seleccionada.codigo_familia
              );
              if (fam_sel && fam_sel.length > 0) {
                this.props.familia_seleccionada.nombre = fam_sel[0].descripcion;
              }
            }
          }
        } else {
          familias_imagenes = this.props.familias.filter((elem) => elem.codigo_padre === null);
        }
        return (
          <Fragment>
            <div className="container-indent familias-menu-imagenes">
              <div className="container-fluid">
                {this.props.familia_seleccionada && Object.keys(this.props.familia_seleccionada).length > 0 ? (
                  <div className="row">
                    <div className="col">
                      <h3>
                        <FontAwesomeIcon icon={faBackward} className="mr-2" onClick={this.limpiarFamilia} style={{ cursor: "pointer" }} /> {this.props.familia_seleccionada.nombre}
                      </h3>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="row">
                  {familias_imagenes.map((familia) => {
                    if (this.props.imagenes_familias && this.props.imagenes_familias.length > 0) {
                      let imagen_familia = this.props.imagenes_familias.filter(
                        (imagen) => imagen.numero_tabla === familia.numero_tabla && imagen.codigo_familia === familia.codigo_familia && imagen.imagen_principal === "S"
                      );
                      if (imagen_familia && imagen_familia.length > 0) {
                        return (
                          <div
                            className="col imagen-familia"
                            style={{
                              backgroundImage: "url(data:image/png;base64," + imagen_familia[0].imagen + ")",
                            }}
                            key={familia.codigo_familia}
                            data-familia={familia.descripcion}
                            data-estadistico={familia.numero_tabla}
                            data-codigo={familia.codigo_familia}
                            onClick={this.onClick}
                          >
                            <div className="overlay"></div>
                            <span className="texto-familia">{familia.descripcion}</span>
                          </div>
                        );
                      } else {
                        return <div key={familia.codigo_familia}></div>;
                      }
                    } else {
                      return <div key={familia.codigo_familia}></div>;
                    }
                  })}
                </div>
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <Fragment></Fragment>;
      }
    }
  }
}

const mapStateToProps = (state) => ({
  familias: state.familias.familias,
  filtros_aplicados: state.articulos.filtros_aplicados,
  imagenes_familias: state.familias.imagenes_familias,
  familia_seleccionada: state.familias.familia_seleccionada,
});

export default withRouter(
  connect(mapStateToProps, {
    limpiarFiltros,
    añadirFiltro,
    setBusqueda,
    setFamiliaSeleccionada,
    getImagenesFamilias,
    getMenuFamilias,
    unsetFamiliaSeleccionada,
  })(FamiliasMenu)
);
