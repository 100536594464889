import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export class GaugeChart extends Component {
  static propTypes = {
    widget: PropTypes.object.isRequired,
  };

  componentDidMount() {}

  componentDidUpdate() {
    window.wookie();
  }

  render() {
    const options = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "solidgauge",
      },
      title: null,
      pane: {
        center: ["50%", "85%"],
        size: "140%",
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      // the value axis
      yAxis: {
        min: this.props.widget.valor_minimo,
        max: this.props.widget.valor_maximo,
        stops: [
          [0.1, "#55BF3B"], // green
          [0.5, "#DDDF0D"], // yellow
          [0.9, "#DF5353"], // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          text: this.props.widget.titulo,
          y: -70,
        },
        labels: {
          y: 16,
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      series: [
        {
          name: this.props.widget.subtitulo_grafico,
          data: [this.props.widget.datos[0][this.props.widget.columna_valor]],
          dataLabels: {
            format:
              '<div style="text-align:center">' +
              '<span style="font-size:25px">{y}</span><br/>' +
              '<span style="font-size:12px;opacity:0.4">' +
              this.props.widget.subtitulo_grafico +
              "</span>" +
              "</div>",
          },
        },
      ],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(GaugeChart);
