import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { enviarRegistroHijo, getUsuariosHijos } from "../../actions/auth";
import { getMenuInformes, getPermisosMenuInformes } from "../../actions/informes";
import { createMessage } from "../../actions/messages";
import { validaEmail, validaPass } from "../../helpers/funciones";
import { Pencil } from "phosphor-react";
import i18n from "../../lang/idiomas";

export class RegistroHijo extends Component {
  static propTypes = {
    enviarRegistroHijo: PropTypes.func.isRequired,
    getUsuariosHijos: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    hijos: PropTypes.array.isRequired,
    informes: PropTypes.array.isRequired,
  };

  state = {
    email: "",
    usuario_web: "",
    password: "",
    nombre: "",
    activo: true,
    activar_pedidos: true,
    activar_informes: true,
    ver_precios: true,
    ver_formulario: false,
    es_edicion: false,
  };

  componentDidMount() {
    this.props.getUsuariosHijos(this.props.user.usuario_web);
    this.props.getMenuInformes();
  }

  componentDidUpdate(prevProps, prevState) {
    window.wookie();
    if (prevState.ver_formulario !== this.state.ver_formulario) {
      let ancla = window.document.getElementById("cabecera-principal");

      if (ancla) {
        ancla.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (JSON.stringify(prevProps.permisos_informes) !== JSON.stringify(this.props.permisos_informes)) {
      if (this.props.permisos_informes && this.props.permisos_informes.length > 0) {
        let permisos = {};
        this.props.permisos_informes.forEach((permiso) => {
          permisos[permiso.informe] = permiso.activo === "S" ? true : false;
        });

        this.setState(permisos);
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.props.user.puede_crear_usuarios === "S") {
      if (
        this.state.email === "" ||
        this.state.usuario_web === "" ||
        (this.state.password === "" && !this.state.es_edicion) ||
        this.state.nombre === "" ||
        this.state.activo === "" ||
        this.state.activar_pedidos === "" ||
        this.state.activar_informes === "" ||
        this.state.ver_precios === ""
      ) {
        this.props.createMessage(i18n.t("usuarios.udecucam"), "error");
      } else if (!this.state.usuario_web.match(/^([a-zA-Z0-9_-]+)$/)) {
        this.props.createMessage(i18n.t("usuarios.uelsolac"), "error");
      } else if (!validaEmail(this.state.email)) {
        this.props.createMessage(i18n.t("usuarios.uemnoval"), "error");
      } else if (!this.state.es_edicion && !validaPass(this.state.password)) {
        this.props.createMessage(i18n.t("usuarios.unocumre"), "error");
      } else {
        let permisos_informes = [];
        if (this.props.informes && this.props.informes.length > 0) {
          this.props.informes.forEach((informe) => {
            if (this.props.informes.filter((inf) => inf.cod_padre === informe.codigo_menu).reduce((accumulator, currentValue) => accumulator + 1, 0) > 0) {
              //tiene hijos
              this.props.informes
                .filter((inf) => inf.cod_padre === informe.codigo_menu)
                .forEach((informe_hijo) => {
                  permisos_informes.push({
                    informe: informe_hijo.nombre_informe,
                    activo: this.state[informe_hijo.nombre_informe] === true ? "S" : "N",
                  });
                });
            } else {
              //no tiene hijos
              permisos_informes.push({
                informe: informe.nombre_informe,
                activo: this.state[informe.nombre_informe] === true ? "S" : "N",
              });
            }
          });
        }

        this.props.enviarRegistroHijo(
          this.state.email,
          this.state.usuario_web,
          this.state.password,
          this.state.nombre,
          this.state.activo === true ? "S" : "N",
          this.state.activar_pedidos === true ? "S" : "N",
          this.state.activar_informes === true ? "S" : "N",
          this.state.ver_precios === true ? "S" : "N",
          permisos_informes,
          this.state.es_edicion === true ? "S" : "N"
        );

        this.cerrarFormulario();
      }
    } else {
      this.props.createMessage(i18n.t("usuarios.unotiper"), "error");
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  abrirFormulario = () => {
    this.setState({
      email: "",
      usuario_web: "",
      password: "",
      nombre: "",
      activo: true,
      activar_pedidos: true,
      activar_informes: true,
      ver_precios: true,
      es_edicion: false,
    });

    if (this.props.informes && this.props.informes.length > 0) {
      let permisos = {};
      this.props.informes.forEach((informe) => {
        if (this.props.informes.filter((inf) => inf.cod_padre === informe.codigo_menu).reduce((accumulator, currentValue) => accumulator + 1, 0) > 0) {
          this.props.informes
            .filter((inf) => inf.cod_padre === informe.codigo_menu)
            .forEach((informe_hijo) => {
              permisos[informe_hijo.nombre_informe] = false;
            });
        } else {
          permisos[informe.nombre_informe] = false;
        }
      });

      this.setState(permisos);
    }

    this.setState({ ver_formulario: true });
  };

  cerrarFormulario = () => {
    this.setState({
      email: "",
      usuario_web: "",
      password: "",
      nombre: "",
      activo: true,
      activar_pedidos: true,
      activar_informes: true,
      ver_precios: true,
      es_edicion: false,
    });

    if (this.props.informes && this.props.informes.length > 0) {
      let permisos = {};
      this.props.informes.forEach((informe) => {
        if (this.props.informes.filter((inf) => inf.cod_padre === informe.codigo_menu).reduce((accumulator, currentValue) => accumulator + 1, 0) > 0) {
          this.props.informes
            .filter((inf) => inf.cod_padre === informe.codigo_menu)
            .forEach((informe_hijo) => {
              permisos[informe_hijo.nombre_informe] = false;
            });
        } else {
          permisos[informe.nombre_informe] = false;
        }
      });

      this.setState(permisos);
    }

    this.setState({ ver_formulario: false });
  };

  editarHijo = (e) => {
    let hijo = this.props.hijos.filter((hijo) => hijo.usuario_web === e.currentTarget.dataset.hijo)[0];

    this.setState({
      email: hijo.email_usuario_web,
      usuario_web: hijo.usuario_web,
      password: "",
      nombre: hijo.nombre,
      activo: hijo.activo === "S" ? true : false,
      activar_pedidos: hijo.activar_pedidos === "S" ? true : false,
      activar_informes: hijo.activar_informes === "S" ? true : false,
      ver_precios: hijo.ver_precios === "S" ? true : false,
      ver_formulario: true,
      es_edicion: true,
    });

    this.props.getPermisosMenuInformes(e.currentTarget.dataset.hijo);
  };

  render() {
    return (
      <div id="tt-pageContent" className="registro-hijos">
        <div className="container-indent">
          <div className="container">
            <h1 className="tt-title-subpages noborder">{i18n.t("usuarios.uucread")}</h1>
            {this.state.ver_formulario === false ? (
              <Fragment>
                <div className="row nuevo-hijo-row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <button className="btn btn-primary" onClick={this.abrirFormulario}>
                      {i18n.t("usuarios.unuhijo")}
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {this.props.hijos && this.props.hijos.length > 0 ? (
                      <Fragment>
                        <div className="tt-shopcart-table lista_hijos_desctop row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row titulos">
                              <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("usuarios.unom")}</div>
                              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("usuarios.uuweb")}</div>
                              <div className="col-sm-12 col-md-3 col-lg-3">{i18n.t("usuarios.uemil")}</div>
                              <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("usuarios.uactv")}</div>
                              <div className="col-sm-12 col-md-2 col-lg-2">{i18n.t("usuarios.uaccnes")}</div>
                            </div>

                            {this.props.hijos.map((hijo) => {
                              return (
                                <div className="row" key={"desktop_" + hijo.usuario_web}>
                                  <div className="col-sm-12 col-md-2 col-lg-2">{hijo.nombre}</div>
                                  <div className="col-sm-12 col-md-3 col-lg-3">{hijo.usuario_web}</div>
                                  <div className="col-sm-12 col-md-3 col-lg-3">{hijo.email_usuario_web}</div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    <input type={"checkbox"} checked={hijo.activo === "S" ? true : false} />
                                  </div>
                                  <div className="col-sm-12 col-md-2 col-lg-2">
                                    <button className="btn btn-primary" onClick={this.editarHijo} data-hijo={hijo.usuario_web}>
                                      <Pencil size={22} weight={"regular"} alt={"Editar hijo"} />
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="tt-shopcart-table lista_hijos_mobile row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {this.props.hijos.map((hijo) => {
                              return (
                                <div className="row" key={"mobile_" + hijo.usuario_web}>
                                  <div className="col-12">
                                    <div className="row ">
                                      <div className="col-6 titulos">{i18n.t("usuarios.unom")}</div>
                                      <div className="col-6"> {hijo.nombre}</div>
                                    </div>

                                    <div className="row ">
                                      <div className="col-6 titulos">{i18n.t("usuarios.uuweb")}</div>
                                      <div className="col-6">{hijo.usuario_web}</div>
                                    </div>
                                    <div className="row ">
                                      <div className="col-6 titulos">{i18n.t("usuarios.uemil")}</div>
                                      <div className="col-6">{hijo.email_usuario_web}</div>
                                    </div>
                                    <div className="row ">
                                      <div className="col-6 titulos">{i18n.t("usuarios.uactv")}</div>
                                      <div className="col-6">{hijo.activo}</div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
        {this.state.ver_formulario ? (
          <div className="container-indent">
            <div className="container">
              <div className="tt-login-form">
                <div className="row	justify-content-center">
                  <div className="col-md-5">
                    <div className="tt-item">
                      <h2 className="tt-title">{i18n.t("usuarios.uforeuwe")}</h2>
                      <div className="form-default justify-content-center">
                        <form onSubmit={this.onSubmit}>
                          <div className="form-group">
                            <label htmlFor="input_nombre">{i18n.t("usuarios.unommin")}</label>
                            <input
                              className="form-control"
                              type="text"
                              name="nombre"
                              id="input_nombre"
                              onChange={this.onChange}
                              value={this.state.nombre}
                              placeholder={i18n.t("usuarios.unoemes")}
                              required
                              readOnly={this.state.es_edicion ? true : false}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="input_email">{i18n.t("usuarios.uemlmin")}</label>
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              id="input_email"
                              onChange={this.onChange}
                              value={this.state.email}
                              placeholder={i18n.t("usuarios.uemcont")}
                              required
                              readOnly={this.state.es_edicion ? true : false}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="input_usuario_web">{i18n.t("usuarios.uusweb")}</label>
                            <input
                              className="form-control"
                              type="text"
                              name="usuario_web"
                              id="input_usuario_web"
                              onChange={this.onChange}
                              value={this.state.usuario_web}
                              placeholder={i18n.t("usuarios.uuwegus")}
                              required
                              readOnly={this.state.es_edicion ? true : false}
                            />
                          </div>
                          {!this.state.es_edicion ? (
                            <div className="form-group">
                              <label htmlFor="input_password">{i18n.t("usuarios.ucont")}</label>
                              <input
                                className="form-control"
                                name="password"
                                id="input_password"
                                onChange={this.onChange}
                                value={this.state.password}
                                placeholder={i18n.t("usuarios.ucont")}
                                required
                                type="password"
                              />
                              <p>{i18n.t("usuarios.ureqmin")}</p>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="activo" name="activo" onChange={this.onChangeCheckbox} checked={this.state.activo} />
                            <label className="form-check-label" htmlFor="activo">
                              {i18n.t("usuarios.uusractv")}
                            </label>
                          </div>
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="activar_pedidos" name="activar_pedidos" onChange={this.onChangeCheckbox} checked={this.state.activar_pedidos} />
                            <label className="form-check-label" htmlFor="activar_pedidos">
                              {i18n.t("usuarios.uactpedi")}
                            </label>
                          </div>
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="activar_informes" name="activar_informes" onChange={this.onChangeCheckbox} checked={this.state.activar_informes} />
                            <label className="form-check-label" htmlFor="activar_informes">
                              {i18n.t("usuarios.uactinfo")}
                            </label>
                          </div>
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="ver_precios" name="ver_precios" onChange={this.onChangeCheckbox} checked={this.state.ver_precios} />
                            <label className="form-check-label" htmlFor="ver_precios">
                              {i18n.t("usuarios.uverppre")}
                            </label>
                          </div>

                          {this.props.informes && this.props.informes.length > 0 && this.state.activar_informes
                            ? this.props.informes
                                .filter((informe) => {
                                  return informe.nombre !== "Carpeta";
                                })
                                .map((informe) => {
                                  if (this.props.informes.filter((inf) => inf.cod_padre === informe.codigo_menu).reduce((accumulator, currentValue) => accumulator + 1, 0) > 0) {
                                    return this.props.informes
                                      .filter((inf) => inf.cod_padre === informe.codigo_menu)
                                      .map((informe_hijo) => {
                                        return (
                                          <div className="form-check">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id={informe_hijo.nombre_informe}
                                              name={informe_hijo.nombre_informe}
                                              onChange={this.onChangeCheckbox}
                                              checked={this.state[informe.nombre_informe] ? this.state[informe.nombre_informe] : false}
                                            />
                                            <label className="form-check-label" htmlFor={informe_hijo.nombre_informe}>
                                              {informe_hijo.nombre}
                                            </label>
                                          </div>
                                        );
                                      });
                                  } else {
                                    return (
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={informe.nombre_informe}
                                          name={informe.nombre_informe}
                                          onChange={this.onChangeCheckbox}
                                          checked={this.state[informe.nombre_informe] ? this.state[informe.nombre_informe] : false}
                                        />
                                        <label className="form-check-label" htmlFor={informe.nombre_informe}>
                                          {informe.nombre}
                                        </label>
                                      </div>
                                    );
                                  }
                                })
                            : ""}

                          <div className="row">
                            <div className="col-auto mr-auto">
                              <div className="form-group">
                                <button type="submit" className="btn btn-primary">
                                  {i18n.t("usuarios.uenvi")}
                                </button>
                                <button type="submit" className="btn btn-primary" onClick={this.cerrarFormulario}>
                                  {i18n.t("usuarios.ucanc")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hijos: state.usuarioshijos.hijos,
  user: state.auth.user,
  informes: state.informes.informes,
  permisos_informes: state.informes.permisos_informes,
});

export default connect(mapStateToProps, {
  enviarRegistroHijo,
  getUsuariosHijos,
  getMenuInformes,
  getPermisosMenuInformes,
  createMessage,
})(RegistroHijo);
